<template>
  <tr :class="['table__row',{'table__row--head' : head}]">
		<slot></slot>
	</tr>
</template>

<script>
export default {
  props: {
    head: Boolean,
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';
.table__row{
	display: block;
	border-bottom: var(--table-border-bottom);
	@include breakpoint(sm){
		display: table-row;
		border-bottom: none;
	}
}
.table__row--head{
	display: none;
	@include breakpoint(sm){
		display: table-row;
	}
}
.table--type-zebra{
	tr:nth-child(even){
		background: var(--table-zebra-even-background);
	}
}
</style>

<!-- Used in : DS -->